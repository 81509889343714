/*
* Third Party
*/
@import "partials/normalize.css";
@import "../../node_modules/bootstrap/dist/css/bootstrap-reboot.min.css";
@import "../../node_modules/bootstrap/dist/css/bootstrap-grid.min.css";
@import "../../node_modules/bootstrap/dist/css/bootstrap.min.css";
@import "../../node_modules/ekko-lightbox/dist/ekko-lightbox.css";
@import "partials/fontawesome-all.css";
@import "partials/font-awesome.css";

/*
* Custom
*/

@import "../../bower_components/owl.carousel/dist/assets/owl.carousel.css";
@import "../../bower_components/owl.carousel/dist/assets/owl.theme.default.css";
@import "../../bower_components/pagePiling.js/dist/jquery.pagepiling.css";
@import "partials/_variables.scss";
@import "partials/_elements.scss";
@import "partials/animate.css";
@import "partials/layout.scss";
@import "partials/style.scss";

@import url('https://fonts.googleapis.com/css?family=Merriweather:300i,400,400i|Oswald:400,500,600,700|Poppins:400,600,900|Roboto:400,500,700');
body,html {

	@include break(mobile) {
		padding-top: 85px;
		overflow: auto !important;
	}
}
html {
	@include break(mobile) {
		padding-top: 0;
	}
}
body {
	min-height: 800px;
}
.container-fluid {
	max-width: 1950px;
	padding: 0 75px;
	@include break(mobile) {
		padding: 0;
		overflow: hidden;
	}
}
header {
	position: fixed;
	top: 0;
	z-index: 9;
	width: 100%;
	@include break(mobile) {
		background: #fff;
	}
	.container-fluid {
		@include break(mobile) {
			border-bottom: 1px solid $light_grey;
		}
	}
	.navbar-brand {
		@include break(mobile) {
			margin: 0;
			padding-top: 1.3125rem;
			padding-bottom: 1.3125rem;
		}
		img {
			@include break(mobile) {
				width: 70px;
				height: auto;
				vertical-align: initial;
			}
		}
	}
	.search {
		border: none;
		padding: 0;
		background: transparent;
	}

}
footer {
	position: fixed;
	bottom: 0;
	width: 100%;
	z-index: 9;
	padding: 1rem 0 0;
	opacity: 0;
	visibility: hidden;
	@include trans(0.15s);
	@include break(medium) {
		padding: 0;
	}
	@include break(mobile) {
		position: relative;
	}
	ul, p {
		margin-bottom: 15px;
	}
}

@media (min-height: 700px) {
	.footer {
		opacity: 1;
		visibility: visible;
	}
}

.navbar {
	padding: 1rem 0;
	@include break(medium) {
		padding: 0;
	}
	@include break(mobile) {
		padding: 0.5rem 2rem;
	}
}
.section.active {
	& + .section {
		.cart-item {
			box-shadow: 0 5px 35px 0 rgba(0,0,0,.2);
		}
	}
}
.section {
	& + .section.active {
		.cart-item {
			box-shadow: 0 5px 35px 0 rgba(0,0,0,.2);
		}
	}
}
.section {
	.cart-item {
		box-shadow: none;

	}
	@include break(mobile) {
		padding: 0;
		opacity: 1;
		visibility: visible;
	}
	&.active {
		.cart-item {
			box-shadow: 0 5px 35px 0 rgba(0,0,0,.2);
		}
	}
}
.pp-easing {
	transition:transform 1s cubic-bezier(.55,.085,0,.99);
}
.cart-item {
	height: 850px;
	height: 81vh;
	min-height: 516px;
	overflow: hidden;
	margin: 95px auto;
	background: #fff;
	box-shadow: 0px 5px 35px 0 rgba(0, 0, 0, 0.2);
	@include break(medium) {
		margin: 65px auto;
	}
	@include break(mobile) {
		box-shadow: none;
		height: auto;
		overflow: visible;
		min-height: auto;
	}
}
/*  P A G E  N A V I G A T I O N
-------------------------------------------------------
 */

#pp-nav {
	span {
		top: 0;
		left: 0;
		width: 14px;
		height: 14px;
		margin: 0 12px;
		border: none !important;
		background-color: $light_grey;
		.dark-theme & {
			top: 7px;
			margin: 0 !important;
			transform: translateY(-50%);
			background-color: rgba(36, 45, 46, 0.6);
		}
	}

	&.right {
		right: 23px;
	}
}
#pp-nav li .active span {
	width: 20px;
	height: 20px;
	margin-top: -3.5px;
	left: -3px;
	background-color: $yellow;
}
.dark-theme {
	#pp-nav {
		li {
			a {
				margin: 22px 0 22px;
			}
		}
	}
	.pp-tooltip {
		color: #fff;
		background-color: $yellow;
		padding: 6px 16px;
		@include font(11px,1.2,#fff,100);
		font-family: $roboto;
		letter-spacing: 1.3px;
		text-transform: uppercase;
		top: -6px;
		margin-left: -5px;
		&:after {
			content: '';
			display: block;
			width: 10px;
			height: 10px;
			position: absolute;
			right: -10px;
			top: 50%;
			transform: translateY(-50%);
			border: 5px solid transparent;
			border-left-color: $yellow;
		}

	}
}

.white-theme {
	#pp-nav li {
		width: 30px;
		height: 30px;
	}
	#pp-nav{
		margin: 0 auto!important;
		left: 50%;
		top: 32px;
		bottom: auto;
		transform: translateX(-50%);
		@include break(medium) {
			top: 26px;
		}
	}

	#pp-nav li .active span {
		width: 20px;
		height: 20px;
		margin-top: -3.5px;
		left: -3px;
		background-color: $yellow;
	}
	#pp-nav li,
	.pp-slidesNav li{
		display:inline-block;
	}
	.pp-tableCell {
		//padding: 1rem;
	}
}
/* M A I N  F O N T S   H1 - H6
----------------------------------------------------
 */
h1 {
	@include font(48px,1.5,$light_grey,600);
	font-family: $poppins;

	@include break(medium) {
		font-size: 30px;
	}
	@include break(neutral) {
		font-size: 26px;
	}
	@include break(mobile) {
		font-size: 28px;
	}
	.dark-theme & {
		color: rgba(255, 255, 255, 0.1);
	}
	.dark-theme #services &,
	.dark-theme #skillsTestimonials &,
	.dark-theme #contact & {
			color: rgba(36, 45, 46, 0.1);
		}

	.main-letter {
		@include font(120px,1,$yellow,600);
		position: relative;
		display: inline-block;
		@include break(medium) {
			font-size: 80px;
		}
		@include break(neutral) {
			font-size: 70px;
		}
		&:after {
			content: '';
			width: 100%;
			height: 6px;
			background-color: $yellow;
			display: block;
			position: absolute;
			bottom: -10px;
		}
	}
	& + p {
		margin-top: 60px;
	}
}
h2 {
	@include font(60px,1.2,$light_black,600);
	font-family: $poppins;
	margin-bottom: 40px;

	@include break(medium) {
		margin-bottom: 20px;
	}
	@include break(neutral) {
		font-size: 38px;
		margin-bottom: 20px;
	}
	.dark-theme & {
		color: #fff;
	}
}
h3 {
	@include font(18px,1,$light_black,500);
	font-family: $roboto;
	letter-spacing: 1.8px;
	text-transform: uppercase;
	margin-bottom: 20px;
}

.tag-line {
	@include font(18px,4,$grey,600);
	font-family: $poppins;
	letter-spacing: 9px;
	margin: 73px 0 0;
	@include break(medium) {
		margin: 20px 0 0;
	}
	@include break(neutral) {
		font-size: 16px;
		line-height: 2;
	}
}
p {
	@include font(20px,1.6,$dark_grey,400);
	font-family: $roboto;
	margin: 0;
	@include break(neutral) {
		font-size: 18px;
	}
}
.author-sign {
	margin-top: 80px;
	@include break(medium) {
		margin-top: 10px;
	}
}
	/* S E C T I O N  A B O U T
	--------------------------------------------------------
	 */
#about {
	.cart-item {
		background: fff;
		.dark-theme & {
			background: $light_black url("img/dark_slide_01.jpg") no-repeat right bottom;
			background-size: contain;

		}
	}
	.social-menu {
		margin: 60px 0 80px;
	}
}
/*  S E R V I C E S
-----------------------------------------------
 */
#services {
	p {
		& + .btn {
			margin-top: 80px;
			@include break(mobile) {
				margin: 40px 0;
			}
		}
	}
	.media {
		padding: 40px;
		box-shadow: 0px 1px 1px 0 rgba(0, 0, 0, 0.2);
		@include trans(0.25s);
		@include break(medium) {
			padding: 20px;
		}
		@include break(mobile) {
			flex-direction: column;
			align-items: center;
			text-align: center;
		}
		&:hover {
			box-shadow: 0px 15px 35px 0 rgba(0, 0, 0, 0.2);
		}
		img {
			@include break(mobile) {
				margin-bottom: 20px;
			}
		}
		p {
			font-size: 16px;
			@include break(medium) {
				font-size: 15px;
			}
		}
	}
}
/* p o r t f o l i o
---------------------------------------------------
 */
#portfolio {
	#filters {
		li {
			float: none;
			span {
				color: $dark_grey;
				&.active {
					color: $yellow;
				}
			}
			& + li {
				@include break(desktop) {
					margin-top: 30px;
				}
				@include break(medium) {
					margin-top: 20px;
				}
				@include break(neutral) {
					margin-top: 10px;
				}
			}
		}
	}
	.portfolio {
		margin-bottom: 30px;
		@include break(medium) {
			margin-bottom: 15px;
		}
	}
	.cart-item {
		background: #fff;
		.dark-theme & {
			background-color: $light_black;
		}
	}
}

/* S K I L L S  &  T E S T I M O N I A L S
-----------------------------------------------------
 */
.progress-skills {
	padding-top: 10px;
	overflow: hidden;
	margin-top: 100px;
	@include break(medium) {
		margin-top: 20px;
	}
	@include break(mobile) {
		margin-bottom: 50px;
	}
	.skills-item {
		margin-bottom: 30px;
	}
	.skill-header {
		@include font(14px,2.57,#a4aeb0,500);
		font-family: $roboto;
		letter-spacing: 2.8px;
		text-transform: uppercase;
	}
}
.progress-bar {
	width: 0%;
	flex-direction: row;
}
.progress-bar.animated {
	width: 90%;
}


/* q u o t e  c a r o u s e l
----------------------------------------------------
 */
#quoteCarousel {
	margin-top: 104px;
	padding: 0;
	position: relative;
	min-height: 360px;
	@include break(medium) {
		margin-top: 20px;
	}
	@include break(mobile) {
		margin: 50px 0;
	}
	.quoteNav {
		width: 80px;
		padding: 40px 0;
		position: relative;
		.carousel-control-next,
		.carousel-control-prev {
			width: 30px;
			height: 30px;
			border-radius: 30px;
		}
		.carousel-control-prev {
			bottom: auto;
			transform: translateX(-50%);
			left: 50%;
			right: auto;
			.carousel-control-prev-icon {
				background-image: url(img/control-next-icon.png);
				width: 30px;
				height: 30px;
				transform: rotate(180deg);

			}

		}

		.carousel-control-next{
			top: auto;
			transform: translateX(-50%);
			left: 50%;
			right: auto;
			.carousel-control-next-icon {
				background-image: url(img/control-next-icon.png);
				background-size: contain;
				width: 30px;
				height: 30px;
				transform: rotate(0deg);
			}
		}
	}
	.carousel-inner {
		margin-left: 30px;
		box-shadow: 0px 15px 35px 0 rgba(0, 0, 0, 0.2);
		border-radius: 2px;
		.carousel-item {
			padding: 30px;

		}
		.item-quote {
			@include font(16px,1.75,$dark_grey,400);
			font-family: $merriweather;
			font-style: italic;
		}
		.author-name {
			@include font(22px,2.18,$yellow,500);
			font-family: $roboto;
		}
		.author-specialty {
			@include font(12px,2.5,$grey,500);
			letter-spacing: 4.8px;
			font-family: $roboto;
			text-transform: uppercase;
		}
	}
	ol {
		width: 80px;
		position: static;
		margin: 0;
		display: block;
		li {
			width: 60px;
			height: 60px;
			border-radius: 50%;
			background-color: #c3ccce;
			box-shadow: 0 1px 1px 0 rgba(0,0,0,.2);
			overflow: hidden;
			margin: 20px auto;
			transition: .25s ease;
			text-indent: inherit;
			&.active {
				width: 80px;
				height: 80px;
				box-shadow: 0 10px 30px 0 rgba(0,0,0,.2);
			}
		}
	}

}

/* B L O G S
------------------------------------------------------------
 */
#blogs {
	.cart-item {
		background-color: #fff;
		.dark-theme & {
			background-color: $light_black;
		}
	}
	h1 + .btn {
		margin-top: 100px;
		@include break(mobile) {
			margin: 40px 0;
		}
	}
}
.dark-theme {
	.container {
		> .row {
			@include break(mobile) {
				padding: 90px 0;
			}
		}
	}
	.modal {
		.container {
			> .row {
				@include break(mobile) {
					padding: 0;
				}
			}
		}
	}
}
.blog-item {
	.dark-theme & {
		@include break(mobile) {
			margin-bottom: 25px;
		}
	}
	.card {
		border-radius: 0;
		border: none;
		.card-img-top {
			border-radius: 0;
		}
		.card-body {
			padding: 40px 0 0;
			.dark-theme & {
				padding-left: 30px;
				padding-right: 30px;
				@include break(neutral) {
					padding-left: 10px;
					padding-right: 10px;
				}
			}
		}
	}
	.date {
		@include font(12px,1.5,$yellow,400);
		font-style: italic;
		letter-spacing: 2.4px;
	}
	.card-title {
		a {
			@include font(22px,1.36,#000,500);
			font-family: $roboto;
		}
	}
	.author {
		@include font(14px,2.57,$grey,300);
		font-style: italic;
		font-family: $merriweather;
	}
}
/* C O N T A C T
-----------------------------------------------------------------
 */
.contact-details {
	margin-top: 100px;
	@include break(medium) {
		margin-top: 50px;
	}
	@include break(mobile) {
		margin-top: 50px;
	}
	li {
		& + li {
			margin-top: 50px;
			@include break(medium) {
				margin-top: 30px;
			}
		}
		p {
			font-size: 16px;
		}
	}
}
.contacts-map {
	box-shadow: 0 15px 35px 0 rgba(0,0,0,.2);
	overflow: hidden;
	#map {
			width: 1400px;
			height: 545px;
			left: 50%!important;
			transform: translateX(-50%);
		@include break(medium) {
			height: 455px;
			width: auto;
		}

	}
}
.navbar-toggler {
	border: none;
	padding: 0;
	width: 30px;
	span {
		float: right;
		display: block;
		height: 3px;
		width: 29px;
		background-color: #242d2e;
		@include trans(0.5s);
		& + span {
			margin-top: 9px;
			@include break(mobile) {
				margin-top: 5px;
			}
		}
		&:first-child {
			width: 75%;
		}
		&:nth-child(3) {
			width: 50%;
		}
		&:hover {
			width: 100%;
		}
	}
	&:hover {
		span {
			width: 100%;
		}
	}
}
.copyright {
	@include font(14px,1.75,$grey,300);
	font-family: $roboto;
}
.social-menu {
	.footer & {
		@include break(mobile) {
			text-align: center;
		}
	}
	li {
		a {
			color: $grey;
			margin-right: 40px;
			font-size: 14px;
			@include trans(0.25s);
			@include break(mobile) {
				margin: 0 20px;
			}
			&:hover {
				color: $yellow;
			}
		}
	}

}


/* m o d a l
--------------------------------------------
 */
#search,
#mainMenu {
	background: rgba(255, 255, 255, 0.9);
}
.modal-dialog {
	max-width: 100%;
	margin: auto;
}
#search.show,
#mainMenu.show {
	display: table !important;
	width: 100%;
	background: rgba(255, 255, 255, 0.9);
	.modal-dialog {
		display: table-cell;
		vertical-align: middle;
		width: 100%;
		height: 100vh;
		margin: auto;
		max-width: 100%;
		.modal-content {
			overflow: hidden;
			position: relative;
			margin: 0 auto;
			transition: all 1s ease;
			max-width: 1770px;
			height: 81vh;
			border-radius: 0;
			border: none;
			box-shadow: 0 20px 46px 0 rgba(0, 0, 0, 0.15);
			@include break(medium) {
				//margin: 0 2rem;
			}
			@include break(mobile) {
				margin: 0;
				height: 100vh;
				box-shadow: none;
			}
			.modal-body {
				padding: 55px 50px;
				position: relative;
				@include break(mobile) {
					padding: 25px;
				}
			}
		}
	}
}
#search,
#mainMenu {
	nav {
		padding: 25px 85px;
		@include break(neutral) {
			padding: 25px;
		}
		@include break(mobile) {
			padding: 0;
		}
		ul {
			> li {
				a {
					@include font(30px,2,$grey,500);
					@include trans(0.25s);
					padding: 0;
					font-family: $oswald;
					@include break(medium) {
						line-height: 1.4;
					}
					&:hover {
						text-decoration: none;
						color: $yellow;
					}
					&.active {
						color: $light_black;
					}
				}
				ul {
					padding-left: 70px;
					@include break(neutral) {
						padding-left: 50px;
					}
					@include break(mobile) {
						padding-left: 50px;
					}
					li {
						a {
							@include font(20px,2.4,$grey,300);
							@include break(medium) {
								line-height: 1.8;
							}
						}
					}
				}
			}
		}
	}
	.social-menu li a {
		margin: 0 20px;
		color: $light_black;
		&:hover {
			color: $yellow;
		}
	}
}
.close {
	position: absolute;
	right: 50px;
	top: 55px;
	z-index: 999;
	@include break(mobile) {
		right: 20px;
		top: 20px;
	}
	span {
		display: block;
		height: 48px;
		width: 48px;
		background: url("img/icon-removeX2.png") no-repeat center;
	}
}
.border-left-1 {
	border-left: 2px solid $light_black;
	@include break(mobile) {
		border-left: none;
	}

}

//PORTFOLIO
.btn-select {
	position: relative;
	padding: 0;
	min-width: 236px;
	width: 100%;
	border-radius: 0;
	border: none;
	margin: 50px 0;
}

.btn-select .btn-select-value {
	padding: 12px 0;
	display: block;
	position: absolute;
	left: 0;
	right: 34px;
	text-align: left;
	text-overflow: ellipsis;
	overflow: hidden;
	border-top: none !important;
	border-bottom: none !important;
	border-left: none !important;
	color: $dark_grey;
	.not-home & {
		@include break(mobile) {
			color: #242d2e;
			padding-left: 12px;
		}
	}

}

.btn-select .btn-select-arrow {
	float: right;
	line-height: 20px;
	padding: 6px 10px;
	top: 0;
	color: $dark_grey;
	.not-home & {
		color: #242d2e;
	}
}

.btn-select ul {
	display: none;
	background-color: white;
	color: black;
	clear: both;
	list-style: none;
	padding: 0;
	margin: 0;
	border-top: none !important;
	position: absolute;
	left: -1px;
	right: -1px;
	top: 33px;
	z-index: 999;
}

.btn-select ul li {
	padding: 3px 6px;
	text-align: left;
}

.btn-select ul li:hover {
	background-color: #f4f4f4;
}

.btn-select ul li.selected {
	color: white;
}



.btn-select.btn-default ul li.selected {
	background-color: #ccc;
}

.btn-select.btn-default ul {
	background-color: white;
	border: #ccc 1px solid;
}

.btn-select.btn-default:hover, .btn-select.btn-default.active {
	background-color: #e6e6e6;
}
.porfolio-sort {
	width: 100%;
	margin: 0 auto;
	.not-home &{
		@include break(mobile) {
			padding: 0 15px;
		}
	}

}
.ekko-lightbox-container > div.ekko-lightbox-item {
	position: relative;
}


/*S E A R C H
---------------------------------------------------------------------
 */
#search {
	form {
		width: 100%;
	}
	.input-group {
		position: relative;
	}
	input[type="text"] {
		border: none;
		border-bottom: 5px solid $light_black;
		padding: 49px 0;
		@include font(48px,0.68,#8c8c8c,700);
		font-family: $oswald;
		width: 100%;
		font-style: normal;
		text-align: left;
		margin: 0;
		@include break(mobile) {
			font-size: 20px;
			padding: 24px 0;
			border-width: 3px;
		}
	}
	.search-btn {
		@include font(48px,1,#8c8c8c,400);
		padding: 0;
		display: block;
		border: none;
		width: 44px;
		height: 44px;
		background: url("img/search-icon-X2.png") no-repeat center;
		box-sizing: content-box;
		position: absolute;
		right: 0;
		top: 50%;
		transform: translateY(-50%);
		@include break(mobile) {
			width: 20px;
			height: 20px;
			background-size: contain;
		}
		&:hover {
			opacity: .75;
			cursor: pointer;
		}
	}
}
.modal.show .modal-dialog,
.modal.fade .modal-dialog {
	transform: translate(0,0);
	transition: opacity .1s;
}


.container-fluid {
	position: relative;
	margin: 0 auto; 
	-webkit-transition: all 1s ease;
  -moz-transition: all 1s ease;
  -o-transition: all 1s ease;
  transition: all 1s ease;	
}
 
#filters {
	margin:0;
	padding:0;
	list-style:none;
	.not-home & {
		margin-bottom: 80px;
	}
}

	#filters li {
		float:left;
	}
	
	#filters li span {
		display: block;
		font-size: 14px;
		font-family: 'Roboto', sans-serif;
		font-weight: 500;
		padding: 0;
		text-decoration:none;
		color:#fff;
		text-transform: uppercase;
		cursor: pointer;
		@include break(neutral) {
			font-size: 12px;
		}
		.not-home &{
			color: #647072;
			padding: 0 20px;
		}
	}
	
	#filters li span.active {
		color:#facd49;
	}

#portfoliolist {
	width: 100%;
	@include break(mobile) {
		width: auto;
	}
}
 
#portfoliolist {
	.portfolio {
		-webkit-box-sizing: border-box;
		-moz-box-sizing: border-box;
		-o-box-sizing: border-box;
		width: 25%;
		margin: 0;
		display: none;
		float: left;
		position: relative;
		@include break(mobile) {
			width: 50%;
		}
		@include break(x-small) {
			width: 100%;
		}
		.not-home & {
			padding: 0 15px 30px;
		}

		&:after {
			display: table;
			content: '';
			clear: both;
		}
			&.w2 {
				width: 50%;
				@include break(x-small) {
					width: 100%;
				}
			}
		&.h1 {
			img {

				max-height: 370px;
			}
		}
		&.h2 {
			img {

			}
		}
	}
}


	.portfolio-wrapper {
		overflow:hidden;
		position: relative !important;
		cursor:pointer;
		&:hover {
			box-shadow: 0px 15px 35px 0 rgba(0, 0, 0, 0.2);
			z-index: 1;
		}
	}

	.portfolio img {
		width:100%;
		position: relative;
		top:0;
		-webkit-transition: all 600ms cubic-bezier(0.645, 0.045, 0.355, 1);
		transition:         all 600ms cubic-bezier(0.645, 0.045, 0.355, 1);
	}

	.portfolio .label {
		position: absolute;
		width: 100%;
		height:100%;
		opacity: 0;
		top: 0;
		left: 0;
		-webkit-transition: all 300ms cubic-bezier(0.645, 0.045, 0.355, 1);
		transition:         all 300ms cubic-bezier(0.645, 0.045, 0.355, 1);
	}

	.portfolio .label-bg {
		background: rgba(255,255,255,.9);
		width: 100%;
		height:100%;
		position: absolute;
		top:0;
		left:0;
	}

	.portfolio .label-text {
		@include font(24px,1.25,#242d2e,500);
		font-family: $roboto;
		position: relative;
		z-index:500;
		text-align: center;
		@include break(neutral) {
			font-size: 14px;
		}
		@include break(x-small) {
			font-size: 24px;
		}
	}

	.portfolio .text-category {
		@include font(14px,2.15,#a4aeb0,300);
		font-style: italic;
		font-family: $merriweather;
		display:block;
		font-size:9px;
	}

	.portfolio:hover .label {
		opacity: 1;
	}




	/* #Clearing */

	/* Self Clearing Goodness */
	.container:after { content: "\0020"; display: block; height: 0; clear: both; visibility: hidden; }

	.clearfix:before,
	.clearfix:after,
	.row:before,
	.row:after {
		content: '\0020';
		display: block;
		overflow: hidden;
		visibility: hidden;
		width: 0;
		height: 0; }
	.row:after,
	.clearfix:after {
		clear: both; }
	.row,
	.clearfix {
		zoom: 1; }

	.clear {
		clear: both;
		display: block;
		overflow: hidden;
		visibility: hidden;
		width: 0;
		height: 0;
	}


/* -------------------------------------------------------------------------------------------------------------------
                                                  General elements
------------------------------------------------------------------------------------------------------------------- */
.container {
	@include  break(large) {
		min-width: 1200px;
	}
	&.large {
		@include break(large) {
			min-width: 1600px;
		}
	}
}

.btn {
	padding: 19px 35px 18px;
	@include font(13px,1,$light_black,700);
	font-family: $roboto;
	text-transform: uppercase;
	letter-spacing: 2.6px;
	border: solid 1px $light_black;
	border-radius: 2px;
	@include trans(0.25s);
	&:hover {
		background-color: $light_black;
		color: #fff;
	}
	&-white {
		color: #fff;
		border-color: #fff;
		&:hover {
			color: $yellow;
			border-color: $yellow;
		}
	}
}
.icon {
	display: inline-block;
	background-repeat: no-repeat;
	background-position: center;
	&-header {
		width: 24px;
		height: 24px;
		@include break(mobile) {
			width: 14px;
			height: 14px;
		}
	}
	&-services {
		width: 100px;
		height: 100px;
	}
	&-design {
		background-image: url("img/icon-serv_design.png");
		.services .services-wrap:hover & {
			background-image: url("img/icon-serv_design_h.png");
		}
	}
	&-brand {
		background-image: url("img/icon-serv_brand.png");
		.services .services-wrap:hover & {
			background-image: url("img/icon-serv_brand_h.png");
		}
	}
	&-photo {
		background-image: url("img/icon-serv_photo.png");
		.services .services-wrap:hover & {
			background-image: url("img/icon-serv_photo_h.png");
		}
	}
	&-search {
		background-image: url("img/search-icon.png");
		background-size: cover;
	}
	&-search_sb {
		background-image: url("img/search-icon_dk.png");
		background-size: cover;
	}
	&-shop {
		background-image: url("img/shop-icon.png");
		background-size: cover;
	}
	&-resize {
		width: 30px;
		height: 30px;
		background-image: url("img/icon_resize.png");
	}
}
.tooltip.show {
	opacity: 1;
	top: -45px !important;
	left: -15px;
}
.tooltip.bs-tooltip-auto[x-placement^=top] .arrow::before,
.tooltip.bs-tooltip-top .arrow::before {
	border-top-color: #facd49;
}
.tooltip-inner {
	background-color:#facd49;
	border-radius: 0;
	@include font(14px,1.2,#fff,400);
	padding: 4px 7px;
}
.progress {
	height: 5px;
	background-color: #e9eaea;
	overflow: visible;
}
.progress-bar {
	justify-content: flex-end;
	width: 0;
	.progress-point {
		width: 14px;
		height: 14px;
		border-radius: 50%;
		background: #facd49;
		box-shadow: 0 0 0 10px rgba(136, 177, 69, 0.1);
		position: relative;
		right: -7px;
	}
}
a.phone {
	color: $dark_grey;
	cursor: default;
	text-decoration: none;
	.not-home & {
		color: #647072;
	}
}
a.mail {
	@extend a.phone;
	cursor: pointer;
	&:hover {
		text-decoration: underline;
	}
}

/* F O R M S
---------------------------------------------------
 */
form {
	textarea,
	input[type="text"],
	input[type="url"],
	input[type="email"] {
		width: 100%;
		text-align: center;
		@include font(14px,1.2,#242d2e,300);
		font-family: $merriweather;
		font-style: italic;
		border: none;
		border-bottom: solid 1px rgba(0, 0, 0, 0.1);
		padding: 20px 0;
		margin-bottom: 20px;
		&:focus {
			outline: none;
			border-color: #000;
		}
		.comments & {
			text-align: left;
			font-style: normal;
			font-size: 16px;
			font-family: Roboto;
		}
	}
	textarea {
		resize: none;
	}
	&.contactsForm {

		margin-top: 100px;
		textarea,
		input {
			@include font(16px,1.2,#242d2e,400);
			font-family: $roboto;
			font-style: normal;
			border-color: rgba(0, 0, 0, 0.1);		}
	}
	.btn {
		margin-top: 40px;
	}
}
::-webkit-input-placeholder { /* Chrome/Opera/Safari */
	color: #a4aeb0;
}
::-moz-placeholder { /* Firefox 19+ */
	color: #a4aeb0;
}
:-ms-input-placeholder { /* IE 10+ */
	color: #a4aeb0;
}
:-moz-placeholder { /* Firefox 18- */
	color: #a4aeb0;
}
*:focus {
	outline: none !important;
	box-shadow: none !important;
}
.date {
	@include font(14px,1.5,#647072,300);
	font-family: $merriweather;
	font-style: italic;
}